/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import AnnouncementsManager from '../../managers/Announcements';

function BrandWidget({ creatorId, tier }) {
  const [announcements, setAnnouncements] = useState([]);

  const getAnnouncements = async () => {
    const data = await AnnouncementsManager.getAnnouncementsByBrand(creatorId, Account.getToken());
    // TODO: Calculate how many are in the announcements array
    if (data && data.announcements) {
      setAnnouncements(data.announcements);
    }
  };

  const handleStickyWidget = () => {
    const stickyBrand = document.getElementById('brandWidget');
    window.addEventListener('scroll', () => {
      if ((stickyBrand.clientHeight + 200) < window.innerHeight) {
        if (window.scrollY > 730 && window.scrollY < 1400) {
          stickyBrand.classList.add('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        } else if (window.scrollY > 1400) {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.add('-psuedo-fixed');
        } else {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        }
      } else {
        stickyBrand.classList.remove('-fixed');
        stickyBrand.classList.remove('-psuedo-fixed');
      }
    });
  };

  useEffect(() => {
    getAnnouncements();
    handleStickyWidget();
  }, []);

  return (
    <div id="brandWidget" className="brand-widget">
      {/* <div className="brand-widget__logo">
        {process.env.LOGO_INVERSE
        
          ? <img src={process.env.LOGO_INVERSE} alt={process.env.SITE_NAME} />
          : <img src={process.env.NAV_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} />}
        
      </div> */}
      <div className="brand-widget__tier-info">
        <div className="brand-widget__tier-title">{tier ? tier.variationName : 'Unknown'}</div>
        {/* Enjoy the perks of your tier */}
      </div>
      {/* <div className="brand-widget__description">{process.env.SITE_DESCRIPTION}</div> */}
      <a href={process.env.CLIENT_WEBSITE} target="_blank" className="brand-widget__button" rel="noreferrer">Go to website</a>
      {announcements.length > 0
      && (
      <div className="brand-widget__updates">
        <div className="brand-widget__updates--header">
          Updates from
          {' '}
          {process.env.SITE_NAME}
        </div>
        <div className="brand-widget__updates--container">
          {announcements.map((announcement) => (
            <div
              key={announcement.announcementId}
              className="brand-widget__updates--item">
              <div dangerouslySetInnerHTML={{ __html: announcement.textContent }}></div>
              <div className="brand-widget__updates--date">
                {moment(announcement.dateCreated).format('MMM DD, YYYY')}
              </div>
            </div>
          ))}
        </div>
        
        {/* <Link to="/account/collection" className="brand-widget__button">View more</Link> */}
      </div>
      )}
    </div>
  );
}

export default BrandWidget;
